
import { getCurrentInstance } from 'vue';
import {
  defineComponent,
  PropType,
  onMounted,
  computed,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { VueSelectInstance } from 'vue-select';
import { FormSelectOptions } from './form.types';
import Icon from '@/components/icon/Icon.vue';
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<FormSelectOptions[]>,
      default: () => [],
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    selectedValue: {
      type: [Object, String, Array],
      default: '',
    },
    allowCustomValue: {
      type: Boolean,
      default: false,
    },
    allowMultipleSelect: {
      type: Boolean,
      default: false,
    },
    noMoreOptionsText: {
      type: String,
      default: 'Sorry, no matching options.',
    },
  },
  setup: (props, ctx) => {
    const instance = getCurrentInstance();
    const vSelectTemplate = ref<VueSelectInstance>();
    const isExpanded = ref<Boolean>(false);

    onMounted(() => {
      const vselectRef = instance!.proxy.$refs.vselect as VueSelectInstance;
      vSelectTemplate.value = vselectRef;
      if (vselectRef.$el) {
        const vsCombobox = vselectRef.$el.querySelector('[role="combobox"]');
        vsCombobox?.removeAttribute('aria-label');
        vsCombobox?.setAttribute('aria-labelledby', `${props.name}-label`);
        vsCombobox?.setAttribute('aria-required', String(props.isRequired));
        vsCombobox?.setAttribute('tabindex', '0');
        vsCombobox?.setAttribute(
          'aria-describedby',
          `validation-msg-${props.name}`
        );

        const vsSearch = vSelectTemplate.value.$el.querySelector(
          '.vs__search'
        ) as HTMLElement;

        (vsCombobox as HTMLElement).addEventListener('keydown', (event) => {
          if (vsCombobox !== document.activeElement || event.key !== 'Enter')
            return;
          event.preventDefault();
          event.stopPropagation();
          vsSearch.focus();
        });
      }
    });

    const noMoreOptionsTextDisplay = computed(() =>
      !props.options.length
        ? props.noMoreOptionsText
        : 'Sorry, no matching options.'
    );

    watch(
      () => props.selectedValue,
      (value) => {
        !props.allowMultipleSelect &&
          value &&
          vSelectTemplate?.value?.updateValue(value);
      }
    );

    const searchedValue = ref<string | object>();

    const handleSearchEvent = (search: String) => {
      if (search) {
        searchedValue.value = vSelectTemplate.value?.filteredOptions[0];
      }
    };

    const handleOptionSelectedEvent = (value: String | Object) => {
      searchedValue.value = value;
      ctx.emit('option:selected', value);
    };

    const handleSearchBlurEvent = () => {
      if (vSelectTemplate.value) {
        vSelectTemplate.value.search = '';
        if (searchedValue.value && !props.allowMultipleSelect) {
          vSelectTemplate.value.updateValue(searchedValue.value);
        }
      }
    };

    const handleDropdownClose = () => {
      setTimeout(() => {
        (
          vSelectTemplate.value?.$el.querySelector(
            '[role="combobox"]'
          ) as HTMLElement
        ).focus();
        isExpanded.value = false;
      });
    };

    const handleDropdownOpen = () => {
      isExpanded.value = true;
    };

    return {
      handleOptionSelectedEvent,
      handleSearchBlurEvent,
      handleSearchEvent,
      handleDropdownClose,
      handleDropdownOpen,
      noMoreOptionsTextDisplay,
      isExpanded,
    };
  },
});
